import axios from "axios";
import { CreateFoodApi, CreateGymApi, DeleteUserApi, EditPasswordApi, EditProfileApi, GenerateCodeGymApi, GetAllFoodApi, GetAllGymApi, GetAllUserApi, LoggedInUserApi, LoginApi, RefreshTokenApi, UpdateUserApi } from "../api";
import { authFileHeader, authHeader } from "./Fack.Backend";

export const loginServiceApi = async (email, password) => {
  try {
    const response = await axios.post(LoginApi, {
      email: email,
      password: password
    });
    return response;
  } catch (error) {
    throw error.response;
  }
}

export const logoutServiceApi = async () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  window.location.href = `${process.env.PUBLIC_URL}/login`;
}

export const getAllFoodServiceApi = async () => {
  try {
    const response = await axios
      .get(GetAllFoodApi, {
        headers: authHeader()
      });
    return response;
  } catch (error) {
    switch (error.response.data['statusCode']) {
      case 401:
        await getAccessTokenServiceApi();
        break;
      default:
        throw error.response;
    }
  }
}

export const getAllUserServiceApi = async () => {
  try {
    const response = await axios
      .get(GetAllUserApi, {
        headers: authHeader()
      });
    return response;
  } catch (error) {
    switch (error.response.data['statusCode']) {
      case 401:
        await getAccessTokenServiceApi();
        break;
      default:
        throw error.response;
    }
  }
}

export const deleteUserServiceApi = async (id) => {
  try {
    const response = await axios
      .delete(`${DeleteUserApi}/${id}`, {
        headers: authHeader()
      });
    return response;
  } catch (error) {
    switch (error.response.data['statusCode']) {
      case 401:
        await getAccessTokenServiceApi();
        break;
      default:
        throw error.response;
    }
  }
}

export const updateUserServiceApi = async (id, data) => {
  try {
    const formData = new FormData();
    data.email && formData.append('email', data.email);
    data.name && formData.append('fullname', data.name);
    data.jenis_kelamin && formData.append('jenis_kelamin', data.jenis_kelamin);
    data.phoneNumber && formData.append('no_telepon', data.phoneNumber);
    // formData.append('referal_code', data.linkGoogle);
    data.age && formData.append('umur', data.age);
    data.weight && formData.append('berat_badan', data.weight);
    data.height && formData.append('tinggi_badan', data.height);
    data.gymFrequency && formData.append('frekuensi_gym', data.gymFrequency);
    // formData.append('role', data.imageFile);
    // formData.append('password', data.imageFile);
    data.imageName && formData.append('alias', data.imageName);
    data.imageFile && formData.append('file', data.imageFile);
    data.calorieTarget && formData.append('target_kalori', data.calorieTarget);

    const response = await axios
      .put(
        `${UpdateUserApi}/${id}`, 
        formData,
        {
          headers: authFileHeader()
        }
      );
    return response;
  } catch (error) {
    switch (error.response.data['statusCode']) {
      case 401:
        await getAccessTokenServiceApi();
        break;
      default:
        throw error.response;
    }
  }
}

export const addFoodServiceApi = async (data) => {
  try {
    const response = await axios
      .post(
        CreateFoodApi, 
        {
          namaMakanan: data.foodName,
          kalori: parseFloat(data.calorie),
          protein: parseFloat(data.protein),
          bahan: data.ingredients,
          cookingStep: data.cookingSteps,
          listFranchise: []
        },
        { headers: authHeader() }
      );
    return response;
  } catch (error) {
    switch (error.response.data['statusCode']) {
      case 401:
        await getAccessTokenServiceApi();
        break;
      default:
        throw error.response;
    }
  }
}

export const addGymServiceApi = async (data) => {
  try {
    const formData = new FormData();
    formData.append('namaGym', data.gymName);
    formData.append('alamatGym', data.gymAddress);
    formData.append('latitude', data.latitude);
    formData.append('longitude', data.longitude);
    formData.append('linkGoogle', data.linkGoogle);
    formData.append('alias', data.imageName);
    formData.append('file', data.imageFile);
    const response = await axios
      .post(
        CreateGymApi, 
        formData,
        { headers: authFileHeader() }
      );
    return response;
  } catch (error) {
    switch (error.response.data['statusCode']) {
      case 401:
        await getAccessTokenServiceApi();
        break;
      default:
        throw error.response;
    }
  }
}

export const getAllGymServiceApi = async () => {
  try {
    const response = await axios
      .get(GetAllGymApi, {
        headers: authHeader()
      });
    return response;
  } catch (error) {
    switch (error.response.data['statusCode']) {
      case 401:
        await getAccessTokenServiceApi();
        break;
      default:
        throw error.response;
    }
  }
}

export const generateCodeGymServiceApi = async (idGym) => {
  try {
    const response = await axios
      .post(GenerateCodeGymApi,
        {
          Uid: idGym
        },
        {
          headers: authHeader()
        }
      );
    return response;
  } catch (error) {
    switch (error.response.data['statusCode']) {
      case 401:
        await getAccessTokenServiceApi();
        break;
      default:
        throw error.response;
    }
  }
}

export const getAccessTokenServiceApi = async () => {
  const refreshToken = localStorage.getItem('refreshToken');
  try {
    const response = await axios
      .post(
        RefreshTokenApi,
        {
          refreshToken: refreshToken ? refreshToken : '',
        },
      )

    switch (response.data['statusCode']) {
      case 200:
        localStorage.setItem('accessToken', response.data['data']['accessToken']);
        localStorage.setItem('refreshToken', response.data['data']['refreshToken']);
        window.location.reload();
        break;
      default:
        await logoutServiceApi();
    }
  } catch (error) {
    switch (error.response.data['statusCode']) {
      case 401:
        await logoutServiceApi();
        break;
      default:
        throw error.response;
    }
  }
}

export const getLoggedInUserServiceApi = async () => {
  try {
    const response = await axios
      .get(
        LoggedInUserApi,
        {
          headers: authHeader()
        }
      );
    return response;
  } catch (error) {
    switch (error.response.data['statusCode']) {
      case 401:
        await getAccessTokenServiceApi();
        break;
      default:
        throw error.response;
    }
  }
}

export const updateProfileServiceApi = async (newProfile) => {
  try {
    const response = await axios
      .put(
        EditProfileApi,
        {
          namaUser: newProfile.name,
          emailUser: newProfile.EmailAddress,
          noTelepon: newProfile.phoneNumber
        },
        {
          headers: authHeader()
        }
      );
    return response;
  } catch (error) {
    switch (error.response.data['statusCode']) {
      case 401:
        await getAccessTokenServiceApi();
        break;
      default:
        throw error.response;
    }
  }
}

export const updatePasswordServiceApi = async (passwordData) => {
  try {
    const response = await axios
      .put(
        EditPasswordApi,
        {
          oldPassword: passwordData.oldPassword,
          newPassword: passwordData.newPassword,
          passwordConfirmation: passwordData.retypePassword
        },
        {
          headers: authHeader()
        }
      );
    return response;
  } catch (error) {
    switch (error.response.data['statusCode']) {
      case 401:
        await getAccessTokenServiceApi();
        break;
      default:
        throw error.response;
    }
  }
}