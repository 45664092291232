import React, { Fragment, useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import AboutMeSidebar from './AboutMeSidebar';
import ProfileHeader from './ProfileHeader';
import { getLoggedInUserServiceApi } from '../../../Services/Api.Service';
import { toast } from 'react-toastify';

const UsersProfileContain = () => {

  const [profile, setProfile] = useState({
    Gym: "",
    KodeGym: "",
    beratBadan: 0,
    email: "",
    firstName: "",
    foto: "",
    frekuensiGym: 0,
    idUser: "",
    jenisKelamin: 0,
    lastName: "",
    noTelepon: "",
    role: "",
    targetKalori: 0,
    tinggiBadan: 0,
    umur: 0
  });

  useEffect(
    () => {
      async function fetchUserProfile() {
        try {
          const resp = await getLoggedInUserServiceApi();
          if (resp.data['statusCode'] !== 200) {
            throw Error('Response Code not 200');
          } else {
            setProfile(resp.data['data']);
          }
        } catch (error) {
          toast.error('failed to load profile');
        }
      }

      fetchUserProfile();
    }, []);

  return (
    <Fragment>
      <Container fluid={true}>
        <div className="user-profile">
          <Row>
            <ProfileHeader profile={profile} />
            <Col xl="4" md="5" lg="12" className="xl-35">
              <div className="default-according style-1 faq-accordion job-accordion">
                <Row>
                  <AboutMeSidebar profile={profile} />
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};
export default UsersProfileContain;