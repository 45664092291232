const api = `https://api.kalorize.com/api/v1`;
export const LoginApi = `${api}/login`;
export const LogoutApi = `${api}/user/logout`;
export const LoggedInUserApi = `${api}/user`;
export const RefreshTokenApi = `${api}/refresh`;
export const EditPasswordApi = `${api}/edit-password`;
export const EditProfileApi = `${api}/edit-user`;

export const CreateGymApi = `${api}/admin/create-gym`;
export const GetAllGymApi = `${api}/gym`;
export const GenerateCodeGymApi = `${api}/admin/create-gymcode`;
export const CreateFoodApi = `${api}/admin/create-makanan`;
export const GetAllFoodApi = `${api}/makanan`;
export const GetAllUserApi = `${api}/admin/get-all-user`;
export const DeleteUserApi = `${api}/admin/delete-user`;
export const UpdateUserApi = `${api}/admin/update-user`;
export const CreateFranchiseApi = `${api}/admin/create-franchise`;
