import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Row, Col, CardHeader, CardBody, CardFooter, Form, FormGroup, Label } from 'reactstrap';
import { Btn, H4 } from '../../../AbstractElements';
import { EditProfile, UpdateProfile, EmailAddress, Name, Phone } from '../../../Constant';
import { getLoggedInUserServiceApi, updateProfileServiceApi } from '../../../Services/Api.Service';
import { Bounce, toast } from 'react-toastify';

const EditMyProfile = () => {
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const onEditSubmit = async (data) => {
    setLoading(true);
    try {
      const resp = await updateProfileServiceApi(data);
      if (resp.data['statusCode'] !== 200) {
        throw resp;
      } else {
        toast.success('Profile update successfully', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
    } catch (error) {
      toast.error('Error update Profile', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
    setLoading(false);
  };

  useEffect(
    () => {
      async function fetchProfile() {
      try {
        const resp = await getLoggedInUserServiceApi();
        if (resp.data['statusCode'] !== 200) {
          throw Error('Response Code not 200');
        } else {
          setValue('name', `${resp.data['data']['firstName']} ${resp.data['data']['lastName']}`);
          setValue('phoneNumber', resp.data['data']['noTelepon']);
          setValue('EmailAddress', resp.data['data']['email']);
        }
      } catch (error) {
        toast.error('Failed to load profile');
      }
    }

    fetchProfile();
    }, [setValue])

  return (
    <Fragment>
      <Form className="card" onSubmit={handleSubmit(onEditSubmit)}>
        <CardHeader className="pb-0">
          <H4 attrH4={{ className: 'card-title mb-0' }}>{EditProfile}</H4>
          <div className="card-options">
            <a className="card-options-collapse" href="#javascript">
              <i className="fe fe-chevron-up"></i>
            </a>
            <a className="card-options-remove" href="#javascript">
              <i className="fe fe-x"></i>
            </a>
          </div>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="5">
              <FormGroup className="mb-3">
                <Label className="form-label">{Name}</Label>
                <input className="form-control" name='name' type="text" placeholder="Your Name" {...register('name', { required: true })} /><span style={{ color: 'red' }}>{errors.company && 'Name is required'} </span>
              </FormGroup>
            </Col>
            <Col sm="6" md="3">
              <FormGroup>
                <Label className="form-label">{Phone}</Label>
                <input className="form-control" name='phoneNumber' type="text" placeholder="08123456789" {...register('phoneNumber', { required: true })} /><span style={{ color: 'red' }}>{errors.phoneNumber && 'Phone Number is required'} </span>
              </FormGroup>
            </Col>
            <Col sm="6" md="4">
              <FormGroup>
                <Label className="form-label">{EmailAddress}</Label>
                <input className="form-control" type="email" name='EmailAddress' placeholder="Email" {...register('EmailAddress', { required: true })} /><span style={{ color: 'red' }}>{errors.EmailAddress && 'Email Address is required'} </span>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
        <CardFooter className="text-end">
          <Btn attrBtn={{ color: 'primary', type: 'submit', disabled: loading ? loading : loading,}}>{loading ? 'LOADING...' : UpdateProfile}</Btn>
        </CardFooter>
      </Form>
    </Fragment>
  );
};
export default EditMyProfile;